import { Button, DialogTitle, DialogActions, Dialog, CircularProgress } from '@material-ui/core';
import { TRANSLATION_KEYS } from 'locales/translationKeys';
import { useTranslation } from 'react-i18next';

export interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  isLoading?: boolean;
}

const ConfirmationDialog = ({
  open,
  title,
  isLoading = false,
  onClose,
  onConfirm,
}: ConfirmationDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const customTitle = title || t(TRANSLATION_KEYS.ARE_YOU_SURE);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">{customTitle}</DialogTitle>
      <DialogActions>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Button autoFocus onClick={onClose}>
              {t(TRANSLATION_KEYS.CANCEL)}
            </Button>
            <Button onClick={onConfirm} color="primary">
              {t(TRANSLATION_KEYS.YES)}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
