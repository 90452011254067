import { useHistory } from 'react-router-dom';
import { Typography, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_KEYS } from 'locales/translationKeys';

const NotFoundPage = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleRedirectToHome = () => {
    history.push('/');
  };

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Typography variant="h2"> 404</Typography>
      <Typography variant="subtitle1">{t(TRANSLATION_KEYS.PAGE_NOT_FOUND)}</Typography>
      <Button color="primary" variant="contained" onClick={handleRedirectToHome}>
        {t(TRANSLATION_KEYS.GO_HOME)}
      </Button>
    </Grid>
  );
};

export default NotFoundPage;
