import { Translation, TRANSLATION_KEYS } from './translationKeys';

const SR_TRANSLATION: Translation = {
  [TRANSLATION_KEYS.CALL_WAITER]: 'Pozovite konobara?',
  [TRANSLATION_KEYS.EMPTY_CART]: 'Korpa je prazna',
  [TRANSLATION_KEYS.ADD_TO_CART]: 'Dodaj u korpu',
  [TRANSLATION_KEYS.ITEM_PRICE]: 'Cijena artikla',
  [TRANSLATION_KEYS.SIDE_DISH]: 'Prilog',
  [TRANSLATION_KEYS.DISH_EXTRAS]: 'Dodaci',
  [TRANSLATION_KEYS.SUBTOTAL]: 'Ukupna cijena (sa prilozima/dodacima)',
  [TRANSLATION_KEYS.COMPLETE_ORDER]: 'Naruči',
  [TRANSLATION_KEYS.ARE_YOU_SURE]: 'Da li ste sigurni?',
  [TRANSLATION_KEYS.CANCEL]: 'Ne',
  [TRANSLATION_KEYS.ORDER_SUCCESS]: 'Narudžba je uspješno poslata',
  [TRANSLATION_KEYS.CALL_WAITER_SUCCESS]: 'Konobar bi trebao brzo doći.',
  [TRANSLATION_KEYS.PAGE_NOT_FOUND]: 'Upppsss... Stranica nije pronadjena',
  [TRANSLATION_KEYS.GO_HOME]: 'Nazad na početnu',
  [TRANSLATION_KEYS.INSTALL_APP]: 'Instalirajte aplikaciju za bolje iskustvo',
  [TRANSLATION_KEYS.INSTALL_APP_LATER]: 'Koristi bez aplikacije',
  [TRANSLATION_KEYS.YES]: 'Da',
  [TRANSLATION_KEYS.ALREADY_CALLED_WAITER]: 'Konobar je već pozvan. Sačekajte malo.',
  [TRANSLATION_KEYS.SOON]: 'Uskoro',
  [TRANSLATION_KEYS.CHOOSE_ONE_ITEM]: 'Morate izabrati jedan',
  [TRANSLATION_KEYS.ADD_NOTE]: 'Ukoliko želite upišite napomenu uz narudžbu...',
  [TRANSLATION_KEYS.NOTE]: 'Napomena',
  [TRANSLATION_KEYS.FREE_ORDER]: 'Čestitamo, dobili ste besplatnu narudžbu',
  [TRANSLATION_KEYS.CLOSE]: 'Zatvori',
};

export default SR_TRANSLATION;
