import { createContext, useReducer } from 'react';
import { CartReducer } from './reducer';
import { ACTION_TYPES } from './actionTypes';
import { CartProviderType, Payload } from './types';
import { initialState } from './constants';

export const CartContext = createContext<undefined | CartProviderType>(undefined);

const CartContextProvider = ({ children }: { children: JSX.Element | Array<JSX.Element> }): JSX.Element => {
  const [state, dispatch] = useReducer(CartReducer, initialState);

  const addProduct = (payload: Payload) => {
    dispatch({ type: ACTION_TYPES.ADD_ITEM, payload });
  };

  const removeProduct = (payload: Payload) => {
    dispatch({ type: ACTION_TYPES.REMOVE_ITEM, payload });
  };

  const increase = (payload: Payload) => {
    dispatch({ type: ACTION_TYPES.INCREASE, payload });
  };

  const decrease = (payload: Payload) => {
    dispatch({ type: ACTION_TYPES.DECREASE, payload });
  };

  const clearCart = () => {
    dispatch({ type: ACTION_TYPES.CLEAR_CART });
  };

  const handleOrder = () => {
    dispatch({ type: ACTION_TYPES.HANDLE_ORDER });
  };

  const setRestaurantData = (payload: Payload) => {
    dispatch({ type: ACTION_TYPES.SET_RESTAURANT_DATA, payload });
  };

  const contextValues = {
    removeProduct,
    addProduct,
    increase,
    decrease,
    clearCart,
    handleOrder,
    setRestaurantData,
    ...state,
  };

  return <CartContext.Provider value={contextValues}>{children}</CartContext.Provider>;
};

export default CartContextProvider;
