import { MenuItem } from 'components/Menu/types';
import { CartItem } from '../types';
import { Action, State } from './types';

const getExtrasTotal = (cartItem: MenuItem): number => {
  const extrasTotal = cartItem.dishAddOns?.reduce((subtot, dish) => Number(dish.price) + subtot, 0);
  const sideDishTotal = cartItem.sideDishGroups?.reduce(
    (groupsTotal, group) =>
      groupsTotal + group.reduce((groupTotal, sideDish) => groupTotal + Number(sideDish.price), 0),
    0,
  );

  return extrasTotal + sideDishTotal;
};

export const sumItems = (cartItems: Array<CartItem>): { itemCount: number; total: number } => {
  const itemCount = cartItems.reduce((tot, product) => tot + product.quantity, 0);
  const total = cartItems
    .reduce((tot, product) => {
      const extrasTotal = getExtrasTotal(product);
      const productTotal = product.price + extrasTotal;

      return tot + productTotal * product.quantity;
    }, 0)
    .toFixed(2);

  return { itemCount, total };
};

const getSubtotal = (cartItem: CartItem, quantity: number): number => {
  const { price } = cartItem;
  const extras = getExtrasTotal(cartItem);

  return (price + extras) * quantity;
};

export const handleProductAdd = (action: Action, state: State): State => {
  if (!action.payload?.product) return { ...state };
  const { quantity, product } = action.payload;

  const newState = [
    ...state.cartItems,
    {
      ...product,
      quantity: Number(quantity),
      subtotal: getSubtotal(action?.payload?.product, Number(quantity)),
    },
  ];

  return {
    ...state,
    ...sumItems(newState),
    cartItems: newState,
  };
};
