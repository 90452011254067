import { useApi } from 'hooks/useApi';

export function useWaiterApi<T>(restaurantId: string, tableNumber: number) {
  const url = `restaurant/${restaurantId}/table/${tableNumber}`;
  const { post, handleError } = useApi<any>(url);

  const postCallWaiter = () => post(null);

  return { postCallWaiter, handleError };
}
