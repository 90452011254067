import { Toolbar, Typography, AppBar, IconButton } from '@material-ui/core';
import NightOutlinedIcon from '@material-ui/icons/Brightness2Outlined';
import SunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import Cart from 'components/Cart/CartButton';
import useCart from 'components/Cart/context/cartStore';
import CallWaiter from 'components/Cart/CallWeiter/CallWaiter';
import LanguageSelector from './LanguageSelector';

interface Props {
  darkState: boolean;
  onThemeStateChange: () => void;
}

function NavigationBar({ darkState, onThemeStateChange }: Props): JSX.Element {
  const { isOrderingActive } = useCart();

  // TODO: NM - Hide cart if it is not menu route
  // const location = useLocation();

  const Icon = darkState ? SunnyOutlinedIcon : NightOutlinedIcon;

  return (
    <AppBar color="default" position="sticky">
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <div style={{ flex: '1 1 30%', display: 'flex' }}>
          <IconButton onClick={onThemeStateChange}>
            <Icon />
          </IconButton>
          <LanguageSelector />
        </div>
        <div style={{ flex: '1 1 30%', textAlign: 'center' }}>
          <Typography variant="h6">Moj Meni</Typography>
        </div>
        <div style={{ flex: '1 1 30%', textAlign: 'right' }}>
          {isOrderingActive && (
            <>
              <CallWaiter />
              <Cart />
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
