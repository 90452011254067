export const TRANSLATION_KEYS = {
  CALL_WAITER: 'Call the waiter' as const,
  EMPTY_CART: 'Empty cart' as const,
  ADD_TO_CART: 'Add to cart' as const,
  ITEM_PRICE: 'Item price' as const,
  SIDE_DISH: 'Side dish' as const,
  DISH_EXTRAS: 'Dish extras' as const,
  SUBTOTAL: 'Subtotal (with side dishes)' as const,
  COMPLETE_ORDER: 'Complete order' as const,
  ARE_YOU_SURE: 'Are you sure' as const,
  CANCEL: 'Cancel' as const,
  ORDER_SUCCESS: 'Order Success' as const,
  CALL_WAITER_SUCCESS: 'Waiter should be here soon.' as const,
  ALREADY_CALLED_WAITER: 'ALREADY_CALLED_WAITER' as const,
  PAGE_NOT_FOUND: 'Page Not found' as const,
  GO_HOME: 'Go Home' as const,
  INSTALL_APP: 'Install app' as const,
  INSTALL_APP_LATER: 'Install app later' as const,
  YES: 'YES' as const,
  SOON: 'SOON' as const,
  CHOOSE_ONE_ITEM: 'CHOOSE_ONE_ITEM' as const,
  ADD_NOTE: 'ADD_NOTE' as const,
  NOTE: 'NOTE' as const,
  FREE_ORDER: 'FREE ORDER' as const,
  CLOSE: 'CLOSE' as const,
};

export interface Translation {
  [TRANSLATION_KEYS.CALL_WAITER]: string;
  [TRANSLATION_KEYS.EMPTY_CART]: string;
  [TRANSLATION_KEYS.ADD_TO_CART]: string;
  [TRANSLATION_KEYS.ITEM_PRICE]: string;
  [TRANSLATION_KEYS.SIDE_DISH]: string;
  [TRANSLATION_KEYS.DISH_EXTRAS]: string;
  [TRANSLATION_KEYS.SUBTOTAL]: string;
  [TRANSLATION_KEYS.COMPLETE_ORDER]: string;
  [TRANSLATION_KEYS.ARE_YOU_SURE]: string;
  [TRANSLATION_KEYS.CANCEL]: string;
  [TRANSLATION_KEYS.ORDER_SUCCESS]: string;
  [TRANSLATION_KEYS.CALL_WAITER_SUCCESS]: string;
  [TRANSLATION_KEYS.PAGE_NOT_FOUND]: string;
  [TRANSLATION_KEYS.GO_HOME]: string;
  [TRANSLATION_KEYS.INSTALL_APP]: string;
  [TRANSLATION_KEYS.INSTALL_APP_LATER]: string;
  [TRANSLATION_KEYS.YES]: string;
  [TRANSLATION_KEYS.ALREADY_CALLED_WAITER]: string;
  [TRANSLATION_KEYS.SOON]: string;
  [TRANSLATION_KEYS.CHOOSE_ONE_ITEM]: string;
  [TRANSLATION_KEYS.ADD_NOTE]: string;
  [TRANSLATION_KEYS.NOTE]: string;
  [TRANSLATION_KEYS.FREE_ORDER]: string;
  [TRANSLATION_KEYS.CLOSE]: string;
}
