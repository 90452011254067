import { Translation, TRANSLATION_KEYS } from './translationKeys';

const EN_TRANSLATION: Translation = {
  [TRANSLATION_KEYS.CALL_WAITER]: 'Call the waiter?',
  [TRANSLATION_KEYS.EMPTY_CART]: 'Your cart is empty.',
  [TRANSLATION_KEYS.ADD_TO_CART]: 'Add to cart',
  [TRANSLATION_KEYS.ITEM_PRICE]: 'Item price',
  [TRANSLATION_KEYS.SIDE_DISH]: 'Side dish',
  [TRANSLATION_KEYS.DISH_EXTRAS]: 'Dish extras',
  [TRANSLATION_KEYS.SUBTOTAL]: 'Subtotal (with side dishes)',
  [TRANSLATION_KEYS.COMPLETE_ORDER]: 'Complete order',
  [TRANSLATION_KEYS.ARE_YOU_SURE]: 'Are you sure?',
  [TRANSLATION_KEYS.CANCEL]: 'Cancel',
  [TRANSLATION_KEYS.ORDER_SUCCESS]: 'Your order has been sent successfully',
  [TRANSLATION_KEYS.CALL_WAITER_SUCCESS]: 'Waiter should be here soon.',
  [TRANSLATION_KEYS.PAGE_NOT_FOUND]: 'Whoops... Page Not Found',
  [TRANSLATION_KEYS.GO_HOME]: 'Go Home',
  [TRANSLATION_KEYS.INSTALL_APP]: 'Install app for better experience',
  [TRANSLATION_KEYS.INSTALL_APP_LATER]: 'Try without app',
  [TRANSLATION_KEYS.YES]: 'Yes',
  [TRANSLATION_KEYS.ALREADY_CALLED_WAITER]: 'Waiter is already on the way',
  [TRANSLATION_KEYS.SOON]: 'Soon',
  [TRANSLATION_KEYS.CHOOSE_ONE_ITEM]: 'You have to choose one item here',
  [TRANSLATION_KEYS.ADD_NOTE]: 'You can write a note for the order...',
  [TRANSLATION_KEYS.NOTE]: 'Note',
  [TRANSLATION_KEYS.FREE_ORDER]: 'Congratulations, you have free order',
  [TRANSLATION_KEYS.CLOSE]: 'Close',
};

export default EN_TRANSLATION;
