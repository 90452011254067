async function parseJSON(response: Response): Promise<string> {
  const text = await response?.text();

  return text ? JSON.parse(text) : '';
}

async function handleStatus(response: Response): Promise<void> {
  if (!response.ok) {
    throw response;
  }

  return new Promise((resolve) => {
    response
      .json()
      .then((json) => resolve(json))
      .catch(() => resolve());
  });
}

async function handleError(response: Response) {
  const body = await parseJSON(response);

  const error = {
    status: response.status,
    statusText: response.statusText,
    body,
  };

  return Promise.reject(error);
}

function getHeaders() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

const fetchData = (url: string, options: RequestInit): Promise<void> =>
  fetch(url, options).then(handleStatus).catch(handleError);

export function request<T>(url: string, methodType: string, body?: T): Promise<any> {
  const options = {
    method: methodType,
    headers: getHeaders(),
    ...(Boolean(body) && { body: JSON.stringify(body) }),
  };

  const baseUrl = process.env.REACT_APP_API_URL;

  return fetchData(`${baseUrl}${url}`, options);
}
