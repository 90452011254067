import { useHistory } from 'react-router-dom';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Badge, IconButton } from '@material-ui/core';
import useCart from './context/cartStore';

const CartButton = (): JSX.Element => {
  const { itemCount } = useCart();
  const history = useHistory();

  const handleCartRoute = () => {
    if (history.location.pathname === '/cart') return;

    history.push('/cart');
  };

  return (
    <IconButton color="primary" aria-label="cart" onClick={handleCartRoute}>
      <Badge badgeContent={itemCount} color="secondary">
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  );
};

export default CartButton;
