import { useState, MouseEvent } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import PublicIcon from '@material-ui/icons/Public';

const LANGUAGE_OPTIONS = [
  {
    key: 'English',
    value: 'en',
  },
  {
    key: 'Bosanski',
    value: 'ba',
  },
  {
    key: 'Hrvatski',
    value: 'hr',
  },
  {
    key: 'Srpski',
    value: 'sr',
  },
];

const LanguageSelector = (): JSX.Element => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentLanguage = LANGUAGE_OPTIONS.find((l) => l.value === i18n.language);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setAnchorEl(null);
  };

  return (
    <>
      <Button aria-controls="language-menu" aria-haspopup="true" onClick={handleClick} startIcon={<PublicIcon />}>
        {currentLanguage?.value}
      </Button>
      <Menu id="language-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {LANGUAGE_OPTIONS.map((language) => (
          <MenuItem key={language.key} onClick={() => changeLanguage(language.value)}>
            {language.key}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelector;
