import { ElementType } from 'react';
import { Redirect, Route } from 'react-router-dom';
import useCart from 'components/Cart/context/cartStore';

interface Props {
  component: ElementType;
  path: string;
}

const OrderingProtectedRoute = ({ component: Component, ...rest }: Props): JSX.Element => {
  const { isOrderingActive } = useCart();

  return (
    <Route
      {...rest}
      render={(props) =>
        isOrderingActive ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/not-found',
            }}
          />
        )
      } // eslint-disable-line react/jsx-curly-newline
    />
  );
};

export default OrderingProtectedRoute;
