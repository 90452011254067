import { useSnackbar } from 'notistack';
import { request } from 'utils/request';

interface UseApi<T> {
  getAll: () => Promise<T>;
  getById: (id: string) => Promise<T>;
  post: (body: T) => Promise<void>;
  put: (body: T) => Promise<void>;
  remove: (id: string) => Promise<void>;
  handleError: (error: Record<string, any>) => void;
}

export const REQUEST_TYPES = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const DEFAULT_ERROR_MSG = 'Došlo je do greške, pokušajte ponovo kasnije.';

export function useApi<T>(endpoint: string): UseApi<T> {
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error: Record<string, any>) => {
    if (error?.current?.body?.nonFieldError) {
      enqueueSnackbar(error.current.body.nonFieldError, { variant: 'error' });
    }

    if (!error?.current?.body) {
      enqueueSnackbar(DEFAULT_ERROR_MSG, { variant: 'error' });
    }

    throw error;
  };

  const getAll = () => request(endpoint, REQUEST_TYPES.GET);

  const getById = (id: string) => request(`${endpoint}/${id}`, REQUEST_TYPES.GET);

  const remove = (id: string) => request(`${endpoint}/${id}`, REQUEST_TYPES.DELETE);

  const post = (body: T) => request(endpoint, REQUEST_TYPES.POST, body);

  const put = (body: T) => request(endpoint, REQUEST_TYPES.PUT, body);

  return {
    getAll,
    getById,
    post,
    put,
    remove,
    handleError,
  };
}
