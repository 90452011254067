import EN_TRANSLATION from './en';
import SR_TRANSLATION from './sr';
import HR_TRANSLATION from './hr';
import BA_TRANSLATION from './ba';

// the translations
const resources = {
  en: {
    translation: EN_TRANSLATION,
  },
  sr: {
    translation: SR_TRANSLATION,
  },
  ba: {
    translation: BA_TRANSLATION,
  },
  hr: {
    translation: HR_TRANSLATION,
  },
};

export default resources;
