import React, { Suspense, useState } from 'react';
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { createMuiTheme } from '@material-ui/core/styles';
import { LinearProgress, ThemeProvider } from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';
import CssBaseline from '@material-ui/core/CssBaseline';
import CartContextProvider from 'components/Cart/context/CartContext';
import NavigationBar from 'components/Common/NavigationBar';
import OrderingProtectedRoute from 'routes/OrderingRoute';
import { saveThemeState, getThemeState } from './utils/storageHelper';
import NotFoundPage from './pages/NotFoundPage';

const MenuPage = React.lazy(() => import('./pages/MenuPage'));
const CartPage = React.lazy(() => import('./pages/CartPage'));

const storageThemeState = getThemeState();

function App(): JSX.Element {
  const [darkState, setDarkState] = useState(storageThemeState === 'dark');
  const palletType = darkState ? 'dark' : 'light';
  const theme = createMuiTheme({
    palette: {
      type: palletType,
      primary: orange,
    },
  });

  const handleThemeStateChange = () => {
    setDarkState(!darkState);
    saveThemeState(!darkState);
  };

  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <CartContextProvider>
              <NavigationBar darkState={darkState} onThemeStateChange={handleThemeStateChange} />

              <Suspense fallback={<LinearProgress color="secondary" />}>
                <Switch>
                  <Route path="/menu/:restaurantId/:tableNumber" component={MenuPage} />
                  <OrderingProtectedRoute path="/cart" component={CartPage} />

                  <Route path="/not-found" component={NotFoundPage} />
                  <Redirect from="*" to="/not-found" />
                </Switch>
              </Suspense>
            </CartContextProvider>
          </ThemeProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </>
  );
}

export default App;
