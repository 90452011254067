import { useState } from 'react';
import { IconButton } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/Common/ConfirmModal/ConfirmModal';
import { TRANSLATION_KEYS } from 'locales/translationKeys';
import useCart from '../context/cartStore';
import { useWaiterApi } from '../api/useWaiterApi';

const CallWaiter = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { restaurantId, tableNumber } = useCart();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { postCallWaiter, handleError } = useWaiterApi(restaurantId, tableNumber);

  const handleCallWaiter = () => {
    setIsLoading(true);

    postCallWaiter()
      .then(() => {
        setModalOpen(false);

        enqueueSnackbar(t(TRANSLATION_KEYS.CALL_WAITER_SUCCESS), { variant: 'success' });
      })
      .catch((error) => {
        if (error.status === 429) {
          enqueueSnackbar(t(TRANSLATION_KEYS.ALREADY_CALLED_WAITER), { variant: 'warning' });
          setModalOpen(false);
          return;
        }

        handleError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <IconButton color="primary" aria-label="cart" onClick={openModal}>
        <NotificationsActiveIcon />
      </IconButton>
      <ConfirmationDialog
        isLoading={isLoading}
        title={t(TRANSLATION_KEYS.CALL_WAITER)}
        open={modalOpen}
        onClose={closeModal}
        onConfirm={handleCallWaiter}
      />
    </>
  );
};

export default CallWaiter;
