export const initialState = {
  cartItems: [],
  checkout: false,
  itemCount: 0,
  total: 0,
  tableNumber: 0,
  restaurantId: '',
  currency: null,
  isDeliveryActive: false,
  isOrderingActive: false,
};
