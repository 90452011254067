import { ACTION_TYPES } from './actionTypes';
import { handleProductAdd, sumItems } from './handlers';
import { Action, State } from './types';

export const CartReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTION_TYPES.ADD_ITEM:
      return handleProductAdd(action, state);
    case ACTION_TYPES.REMOVE_ITEM:
      return {
        ...state,
        ...sumItems(state.cartItems.filter((item) => item.id !== action.payload?.product?.id)),
        cartItems: [...state.cartItems.filter((item) => item.id !== action.payload?.product?.id)],
      };
    case ACTION_TYPES.INCREASE: {
      const findCartItem = state.cartItems.find((item) => item.id === action.payload?.product?.id);

      findCartItem.quantity += 1;

      const newCartItems = [...new Map(state.cartItems.map((item) => [item.id, item])).values()];

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: newCartItems,
      };
    }
    case ACTION_TYPES.DECREASE: {
      const findCartItem = state.cartItems.find((item) => item.id === action.payload?.product?.id);

      findCartItem.quantity -= 1;

      const newCartItems = [...new Map(state.cartItems.map((item) => [item.id, item])).values()];

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: newCartItems,
      };
    }
    case ACTION_TYPES.HANDLE_ORDER:
      return {
        ...state,
        cartItems: [],
        checkout: true,
        ...sumItems([]),
      };

    case ACTION_TYPES.SET_RESTAURANT_DATA:
      return {
        ...state,
        tableNumber: action.payload?.tableNumber || 0,
        restaurantId: action.payload?.restaurantId || '',
        isDeliveryActive: Boolean(action.payload?.isDeliveryActive),
        isOrderingActive: Boolean(action.payload?.isOrderingActive),
        currency: action.payload?.currency || null,
      };
    case ACTION_TYPES.CLEAR_CART:
      return {
        ...state,
        cartItems: [],
        checkout: false,
        ...sumItems([]),
      };
    default:
      return state;
  }
};
